/*********************************************
 * Infosystem AG
 * Web Application CSS Standard
 * 
 *          -----> ROOT <-----
 *
 ********************************************/

/*-----------------------------IMPORTS-----------------------------*/
@import "infosystem-colors.scss";
@import "infosystem-fonts.scss";

/*-----------------------------VARIABLES-----------------------------*/

/*-----------------------------FONTS-----------------------------*/

html {
  font-family: -apple-system, BlinkMacSystemFont, IBMPlexSans-Regular, Tahoma, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  -moz-font-feature-settings: "liga", "clig", "pnum";
  -webkit-font-feature-settings: "liga", "clig", "pnum";
  font-feature-settings: "liga", "clig", "pnum";
  font-variant-numeric: proportional-nums;
  color: $is-text;
  height: 100%;
}
