﻿/*********************************************
 * Infosystem AG
 * Web Application CSS Standard
 * 
 *          -----> FONTS <-----
 *
 ********************************************/

/*-----------------------------IMPORTS-----------------------------*/

/*-----------------------------VARIABLES-----------------------------*/

/*-----------------------------FONTS-----------------------------*/

@font-face {
  font-family: IBMPlexSans-Regular;
  src: url("../../fonts/IBMPlexSans-Regular.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: IBMPlexSans-Medium;
  src: url("../../fonts/IBMPlexSans-Medium.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: IBMPlexSans-Bold;
  src: url("../../fonts/IBMPlexSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: IBMPlexSans-ExtraLight;
  src: url("../../fonts/IBMPlexSans-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: IBMPlexSans-Thin;
  src: url("../../fonts/IBMPlexSans-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: IBMPlexSans-Light;
  src: url("../../fonts/IBMPlexSans-Light.ttf") format("truetype");
  font-style: normal;
}

/* Fonts */

strong,
b {
  font-weight: 600;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-variant-numeric: oldstyle-nums;
  -moz-font-feature-settings: "onum";
  -webkit-font-feature-settings: "onum";
  font-feature-settings: "onum";
}

h1 {
  font-size: 1.6rem !important;
  font-weight: 500 !important;
  color: $is-text-title !important;
}

h2 {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  color: $is-text-title !important;
}

h3 {
  font-size: 1.2rem !important;
  font-weight: 5 00 !important;
  color: $is-text-title !important;
}

h4 {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: $is-text-sub-title !important;
}

h5 {
  font-size: 0.8rem !important;
  color: $is-text-sub-title !important;
}

h6 {
  font-size: 0.6rem !important;
  color: $is-text-sub-title !important;
}
