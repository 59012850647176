$smartphones: #{"only screen and (max-width: 768px)"};
$tablets: #{"only screen and (max-width: 992px)"}; /*  Fonts  */
$font-size-large: 30px;
$font-size-medium: 24px;
$font-size-medium-xs: 18px;
$font-size-base: 14px;
$font-size-small: 13px;
$font-size-xsmall: 12px;
$font-size-weight: 500;
$font-size-h2: floor(($font-size-base * 1.9)); // ~30px /* Toolbar */
$toolbar-wrapper-margin: 10px;
$toolbar-wrapper-shadow: rgba(0, 0, 0, 0.1) 0 0 1px 1px;
$toolbar-height: 43px;
$toolbar-bg: $is-grey-light;
$toolbar-border: #ddd; /* Titlebar */
$titlebar-height: 58px; /* Top Navigation */
$navbar-height: 50px;
$navbar-inverse-color: #fff;
$navbar-inverse-bg: $is-red;
$navbar-inverse-link-color: #fff;
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: lighten($navbar-inverse-bg, 10%);
$navbar-inverse-link-active-color: $is-red;
$navbar-inverse-link-active-bg: $toolbar-bg;
$navbar-inverse-link-disabled-color: #fff;
$navbar-inverse-link-disabled-bg: transparent; /* Header */
$header-height: $titlebar-height;

#main-wrapper {
  overflow: hidden;
  padding: 0px;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background-color: $body-bg;
  transition: all 0.3s;
}

/*Titelbar*/
#title-bar {
  position: relative;
  background: $body-bg;
}

#title-bar .title-bar-text {
  margin: 0;
  padding: 10px 10px 14px 10px;
  font-size: font-size-large;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;

  //Tablets
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: font-size-medium;
  }

  // smartphones
  @media (min-width: 481px) and (max-width: 767px) {
    font-size: font-size-medium-xs;
  }
}

#title-bar .title-bar-detail-text {
  margin: 0;
  padding: 8px 1px 5px 10px;
  font-size: font-size-medium;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: font-size-medium;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    font-size: font-size-medium-xs;
  }
}

#title-bar .title-sub-bar-detail-text {
  margin: 0;
  padding: 1px 1px 1px 10px;
  font-size: font-size-base;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.1;
}

/*Navbar - Site's*/
#navigation-bar {
  background-color: $body-bg;
  position: relative;
  width: auto;
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;

  .navbar-toggler {
    border: none;
    padding: 0px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .nav-link {
    padding: 1px;
    margin-right: 1em;
    font-size: font-size-base;
  }

  .nav-link.active {
    border-bottom: 1px solid;
  }
}

/*Content für router-outlet*/
#content-wrapper {
  position: relative;
  background-color: $body-bg;

  .k-button {
    text-transform: none;
  }

  .content-indicator {
    .k-i-loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 48px;
      background-color: rgba(255, 255, 255, 0.3);
      color: $is-sky;
      z-index: 1000;
    }
  }

  .toolbar-grid-tools.k-toolbar {
    box-shadow: none;

    .k-button-md {
      padding-block: 6px;
      padding-inline: 6px;
    }
  }

  .required-indicator-red {
    padding-left: 3px;
    color: $is-red-dark;
  }

  .k-input-outline:focus-within,
  .k-picker-outline:focus-within,
  .k-input-outline.k-focus,
  .k-picker-outline.k-focus {
    border-color: $is-grey-dark;
  }

  .k-input-solid {
    border-color: $is-grey-dark-60 !important;
  }

  .k-input-outline::after,
  .k-picker-outline::after {
    border-width: 1px;
  }

  .k-input-flat:focus-within,
  .k-picker-flat:focus-within,
  .k-input-flat:hover,
  .k-picker-flat:hover,
  .k-input-flat.k-focus,
  .k-picker-flat.k-focus {
    border-color: rgba(0, 0, 0, 0.38);
  }

  .k-input-flat::after {
    border-bottom-width: 1px;
  }

  .k-badge-edge.k-top-end {
    transform: translate(-0%, -85%);
  }

  .k-badge-solid-light {
    background-color: transparent;
  }

  .k-badge-lg {
    font-size: medium;
  }

  .k-toolbar .k-toolbar-overflow-button {
    padding: 0px;
  }

  .k-grid .k-cell-inner .k-grid-filter,
  .k-grid .k-cell-inner .k-header-column-menu,
  .k-grid .k-cell-inner .k-grid-header-menu {
    margin-inline-end: 6px;
    position: absolute;
    right: 0;
    top: -4px;
    padding: 0;
  }

  .k-grid-md .k-grid-header .k-grid-header-menu {
    width: 16px;
  }

  .k-grid {
    display: flex;
    font-size: small;
  }

  .k-grid th,
  .k-grid td {
    padding: 2px 4px;
    white-space: nowrap;
  }

  .k-grid-toolbar .k-button + .k-button {
    margin-left: 4px;
  }

  .k-grid-toolbar .k-button {
    vertical-align: middle;
  }

  .k-grid .k-hierarchy-cell .k-icon {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .k-grid-header,
  .k-grid-header-wrap,
  .k-grouping-header,
  .k-grid .k-table-th,
  .k-grid td,
  .k-grid .k-table-td,
  .k-grid-footer,
  .k-grid-footer-wrap,
  .k-grid-content-locked,
  .k-grid-footer-locked,
  .k-grid-header-locked,
  .k-filter-row > .k-table-th,
  .k-filter-row > td,
  .k-filter-row > .k-table-td {
    border-color: rgba(0, 0, 0, 0.08);
  }

  .k-table-md {
    font-size: $font-size-small;
  }

  .k-grid tr .right {
    text-align: right;
    padding-right: 1em;
  }

  .k-grid tr .center {
    text-align: center;
    justify-content: center;
  }

  .k-grid a:hover {
    font-weight: 600;
  }

  .k-form-md .k-form-field,
  .k-form-md .k-form-buttons {
    margin-top: 0;
  }

  .k-form-hint,
  .k-form-error {
    margin-top: unset;
  }
}

#content-list,
.content-list {
  height: inherit;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  .k-grid {
    border-style: none;
  }

  .k-grid-toolbar {
    border-style: none;
  }

  .k-grid .k-grid-aria-root {
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
  }

  .k-i-filter::before {
    font-size: 0.7em;
  }

  .k-grid .k-grid-filter {
    padding-top: 1.25em;
    padding-left: 1.5em;
    // IE10+ CSS here
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      padding-top: 0em;
    }
    // Edge
    @supports (-ms-ime-align: auto) {
      padding-top: 0em;
    }
  }

  .k-grid-header .k-grid-filter.k-state-active {
    color: $is-red;
    background-color: inherit;
  }
}

#content-detail-frame {
  height: calc(100vh - $header-height - $toolbar-height);
  overflow-y: auto;
  overflow-x: hidden;
}

#content-detail {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  max-height: inherit;

  .full-size {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 10;
  }

  #content-detail-titel {
    margin-top: -25px;
    margin-bottom: 1px;
  }

  .k-window-content {
    .k-grid th,
    .k-grid td {
      padding: 2px 4px;
    }
  }
}
