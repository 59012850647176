@import 'variables.scss';
@import 'apps/levab2x-app-onlinecheckout/src/styles.scss';

kendo-loader {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Scrollbar modifications */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: lightgrey;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 4px;
}

.k-switch-on .k-switch-thumb {
  background-color: white !important;
}

.k-button-solid-primary {
  color: white !important;
}

.k-avatar-solid-primary {
  color: white !important;
}

.k-notification-group {
  // allow new lines and spaces in notification popup
  white-space: pre-wrap !important;
}

$kendo-switch-sizes: (
  sm: (
    font-size: null,
    track-width: 24px,
    track-height: 13px,
    thumb-width: 10px,
    thumb-height: 10px,
    thumb-offset: 2px,
    label-offset: null,
  ),
  md: (
    font-size: null,
    track-width: 56px,
    track-height: 30px,
    thumb-width: 24px,
    thumb-height: 24px,
    thumb-offset: 3px,
    label-offset: null,
  ),
  lg: (
    font-size: null,
    track-width: 64px,
    track-height: 34px,
    thumb-width: 28px,
    thumb-height: 28px,
    thumb-offset: 3px,
    label-offset: null,
  ),
);

.draggable {
  cursor: grab;
  cursor: -webkit-grab; /* For older WebKit browsers */
}

.draggable:active {
  cursor: grabbing;
  cursor: -webkit-grabbing; /* For older WebKit browsers */
}
