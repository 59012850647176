@import "./site-content.scss";

body {
  overflow: hidden;
}

#wrapper {
  display: flex;
  align-items: stretch;

  .k-window-title {
    padding-bottom: 0.2em;
  }

  .loading-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #busy {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10100;
    position: absolute;

    .busy-box {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 75px;
      width: 120px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      text-align: center;
      padding: 10px;
      color: $body-bg;
      background-color: $busy-box-color;
      box-shadow: 1px 1px 10px $is-grey-light;

      p {
        margin: 1px;
      }
    }
  }

  .info-icon {
    position: sticky;
    left: 100%;
    padding-right: 0.5em;
    color: $is-grey-dark;
  }

  .info-icon:hover {
    color: $is-black;
  }

  .error-modal-red .k-button {
    background-color: $is-red;
    border-color: $is-red;
    color: $is-white;
  }

  .error-modal-yellow .k-button {
    background-color: $is-yellow;
    border-color: $is-yellow;
  }

  .required-indicator-red {
    padding-left: 3px;
    color: $is-red-dark;
  }
}
