@import "../../site-content.scss";
@import "../../infosystem-colors.scss";
@import "../../custom/kendo-theme-bootstrap";

.baf-input-readonly {
  background-color: rgb(0, 0, 0, 0.02) !important;
  .k-chip-actions kendo-svgicon {
    display: none !important;
  }
}

kendo-formfield > kendo-label {
  font-size: $font-size-xsmall !important;
  margin-top: 12px !important;
  margin-bottom: 0 !important;
  display: block !important;
}

kendo-formfield > kendo-floatinglabel {
  font-size: $font-size-xsmall !important;
  margin-top: 12px !important;
  margin-bottom: 0 !important;
  display: block !important;
}

.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  min-height: 25px;
  padding-block: 0.1rem !important;
  padding-inline: 0.25rem !important;
  font-size: $font-size-small !important;
}

.k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
  min-height: 25px;
  padding-block: 0.11rem !important;
  padding-inline: 0.1rem !important;
  font-size: $font-size-small !important;
}

.k-picker-outline {
  border-color: $is-grey-dark-60 !important;
}

.k-picker-outline:hover,
.k-picker-outline.k-hover {
  // border-color: rgba(33, 37, 41, 0.5) !important;
  border-color: var(--bs-border-color) !important;
  color: #212529 !important;
  background-color: white !important;
}

.k-chip-md {
  padding-block: 2px !important;
  padding-inline: 2px !important;
  font-size: $font-size-small !important;
}

.k-chip-md .k-chip-action {
  padding-block: 2px !important;
  padding-inline: 2px !important;
  font-size: $font-size-small !important;
}

.k-input-md .k-input-values,
.k-picker-md .k-input-values {
  padding: 0.1rem !important;
}

.k-clear-value {
  padding-block: 0.1rem !important;
}

.k-input-md .k-input-validation-icon {
  padding-block: 0.1rem !important;
}

.k-form-hint,
.k-form-error {
  font-size: $font-size-xsmall !important;
}

.k-table-thead,
.k-table-header,
.k-table-group-sticky-header {
  background-color: transparent !important;
  font-weight: 600;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
}
