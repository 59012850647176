$nav-link-padding-y: 8px;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, IBMPlexSans-Regular,
  Tahoma, Verdana, sans-serif;

$enable-rounded: false;
$enable-shadows: true;
$enable-gradients: true;
$enable-transitions: true;
$enable-grid-classes: true;
$enable-caret: true;
$enable-print-styles: true;

@import '../../../../../../node_modules/bootstrap/scss/bootstrap.scss';
