$kendo-color-white: $is-white; // stylelint-disable-line scss/dollar-variable-default
$kendo-color-black: $is-black; // stylelint-disable-line scss/dollar-variable-default

// Options
$kendo-enable-rounded: true;
$kendo-enable-shadows: true;
$kendo-enable-gradients: true;
$kendo-enable-transitions: true;
$kendo-enable-focus-contrast: false;
$kendo-enable-typography: false;

$kendo-use-input-button-width: true;
$kendo-use-input-spinner-width: true;
$kendo-use-input-spinner-icon-offset: false;

$kendo-auto-bootstrap: true;

// Typography
$kendo-font-family: -apple-system, BlinkMacSystemFont, IBMPlexSans-Regular, "Helvetica Neue", sans-serif;

/// @group typography
$kendo-font-family-sans-serif: -apple-system, BlinkMacSystemFont, IBMPlexSans-Regular, "Helvetica Neue", "Noto Sans",
  "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Color settings
$kendo-is-dark-theme: false;

// Theme colors
/// The color that focuses the user attention.
/// Used for primary buttons and for elements of primary importance across the theme.
/// @group color-system
/// @type Color
///$kendo-color-primary: #ff6358 !default;
///$kendo-color-primary-lighter: k-color-tint($kendo-color-primary, 2);
$kendo-color-primary-darker: k-color-shade($kendo-color-primary, 2);

/// The color used along with the primary color denoted by $kendo-color-primary.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
/// @type Color
$kendo-color-primary-contrast: k-contrast-legacy($kendo-color-primary);

/// The secondary color of the theme.
/// @group color-system
/// @type Color
$kendo-color-secondary: $is-grey-dark;
$kendo-color-secondary-lighter: k-color-tint($kendo-color-secondary, 2);
$kendo-color-secondary-darker: k-color-shade($kendo-color-secondary, 2);

/// The color used along with the secondary color denoted by $kendo-color-secondary.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
/// @type Color
$kendo-color-secondary-contrast: k-contrast-legacy($kendo-color-secondary);

/// The tertiary color of the theme.
/// @group color-system
/// @type Color
$kendo-color-tertiary: #03a9f4;
$kendo-color-tertiary-lighter: k-color-tint($kendo-color-tertiary, 2);
$kendo-color-tertiary-darker: k-color-shade($kendo-color-tertiary, 2);

/// The color used along with the tertiary color denoted by $kendo-color-tertiary.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
/// @type Color
$kendo-color-tertiary-contrast: k-contrast-legacy($kendo-color-tertiary);

/// The color for informational messages and states.
/// @group color-system
/// @type Color
$kendo-color-info: #0058e9;
$kendo-color-info-lighter: k-color-tint($kendo-color-info, 2);
$kendo-color-info-darker: k-color-shade($kendo-color-info, 2);

/// The color for success messages and states.
/// @group color-system
/// @type Color
$kendo-color-success: $is-green;
$kendo-color-success-lighter: k-color-tint($kendo-color-success, 2);
$kendo-color-success-darker: k-color-shade($kendo-color-success, 2);

/// The color for warning messages and states.
/// @group color-system
/// @type Color
$kendo-color-warning: $is-yellow;
$kendo-color-warning-lighter: k-color-tint($kendo-color-warning, 2);
$kendo-color-warning-darker: k-color-shade($kendo-color-warning, 2);

/// The color for error messages and states.
/// @group color-system
/// @type Color
$kendo-color-error: $is-red;
$kendo-color-error-lighter: k-color-tint($kendo-color-error, 2);
$kendo-color-error-darker: k-color-shade($kendo-color-error, 2);

/// The dark color of the theme.
/// @group color-system
/// @type Color
$kendo-color-dark: $is-grey-dark;

/// The light color of the theme.
/// @group color-system
/// @type Color
$kendo-color-light: $is-white;

/// Inverse color of the theme. Depending on the theme luminance dark or light, it will be light or dark
/// @group color-system
$kendo-color-inverse: if($kendo-is-dark-theme, $kendo-color-light, $kendo-color-dark);

$input-focus-box-shadow: 0 0 0 0.05rem rgba(0, 0, 0, 0.5);
$input-focus-border-color: transparent;
$kendo-picker-focus-shadow: 0 0 0 0.05rem rgba(0, 0, 0, 0.5);
$picker-focus-border-color: transparent;
$kendo-invalid-shadow: 0 0 0 0.1rem $is-red;
$kendo-selected-bg: $primary-color-40;
$kendo-selected-text: black;
$kendo-input-disabled-bg: rgba(0, 0, 0, 0.05);
$kendo-checkbox-focus-border: transparent;
$kendo-checkbox-focus-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.5);
$kendo-grid-header-bg: transparent;
$kendo-grid-header-gradient: transparent;
$kendo-grid-footer-bg: transparent;
$kendo-toolbar-bg: transparent;
$kendo-grid-bg: transparent;

$kendo-theme-colors: (
  "primary": $kendo-color-primary,
  "secondary": $kendo-color-secondary,
  "tertiary": $kendo-color-tertiary,
  "info": $kendo-color-info,
  "success": $kendo-color-success,
  "warning": $kendo-color-warning,
  "error": $kendo-color-error,
  "dark": $kendo-color-dark,
  "light": $kendo-color-light,
  "inverse": $kendo-color-inverse,
);
$kendo-button-border-width: 1px !default;
$kendo-input-border-width: 1px !default;
$kendo-toolbar-border-width: 0px;
@import "../../../../../../node_modules/@progress/kendo-theme-bootstrap/dist/all.scss";
